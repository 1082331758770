import { Config } from './types';
export const baseApiUri = process.env.REACT_APP_API_URL || 'https://inwf4fbyvm.eu-west-1.awsapprunner.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'CheckHQ',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/83ae84e1-49c3-40e0-ead5-6505ab04d300/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#0070ff',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#0070ff',
};
